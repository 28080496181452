import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import Registry from "./Registry";
import Alert from "./Alert";
import AlertContext, { alertReducer } from "./AlertContext";
import { Stack } from "@mui/material";
import logo from "./rewatt-logo-white-icon.png";

// ATTN: update this when we add a new csv file
const CSV_FILE = "reg_snapshot_2311131908.csv";

const getDate = () => {
  const regex = /^reg_snapshot_(\d+)\.csv/gm;
  const matches = regex.exec(CSV_FILE);
  return matches[1];
};

const App = () => {
  const date = DateTime.fromFormat(getDate(), "yyMMddHHmm", { zone: "UTC" });
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [state, dispatch] = React.useReducer(alertReducer, {});

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" alignItems="center" pr={2}>
            <img
              alt="Logo"
              src={logo}
              height={37}
            />
          </Box>
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
              Rewatt Registries
            </Typography>
            <Typography variant="caption">
              CSA AEOR Listing
            </Typography>
          </Stack>
          <Typography
            component="span"
            color="#ccc"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Updated{" "}
            <Typography variant="h6" component="span" color="common.white">
              {date.setZone(tz).toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
        }}
      >
        <AlertContext.Provider value={{ state, dispatch }}>
          <Alert />
          <Registry csvFile={CSV_FILE} />
        </AlertContext.Provider>
      </Box>
    </Box>
  );
};

export default App;
