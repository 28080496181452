import * as React from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Papa from "papaparse";
import { useEffect, useMemo, useState } from "react";
import { pick } from "lodash";
import { styled } from "@mui/material/styles";
import { renderCellExpand } from "./GridCellExpand";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import MessageDialog from "./MessageDialog";

const StyledDataGrid = styled(DataGridPro)`
  /* bold headers */
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 800;
  },
  
  /* get rid of the last separator in the cell header */
  & .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
    display: none;
  },
`;

const Registry = ({ csvFile }) => {
  const [data, setData] = useState([]);
  const [isOpen, openMessageDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    async function fetchCsv() {
      const response = await fetch(`resources/${csvFile}`);
      const text = await response.text();
      const parsed = Papa.parse(text, { header: true });
      setData(
        parsed.data.map((row, idx) => ({
          id: idx,
          ...pick(row, [
            "Project Identifier",
            "Project Title",
            "Emission Offset Project Developer",
            "Quantification Protocol",
            "Vintage",
            "Quantity",
            "Current Owner",
            "Emission Offset Status",
          ]),
        }))
      );
    }
    fetchCsv();
  }, [csvFile]);

  const columns = useMemo(
    () => [
      {
        field: "Project Identifier",
        description: "Project Identifier",
        headerName: "Project ID",
        flex: 1,
      },
      {
        field: "Project Title",
        description: "Project Title",
        headerName: "Title",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "Emission Offset Project Developer",
        description: "Emission Offset Project Developer",
        headerName: "Developer",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "Quantification Protocol",
        description: "Quantification Protocol",
        headerName: "Quantification Protocol",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "Vintage",
        description: "Vintage",
        headerName: "Vintage",
        width: 100,
        type: "number",
        align: "right",
        headerAlign: "right",
      },
      {
        field: "Quantity",
        description: "Quantity",
        headerName: "Quantity",
        width: 100,
        type: "number",
      },
      {
        field: "Current Owner",
        description: "Current Owner",
        headerName: "Owner",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "Emission Offset Status",
        description: "Emission Offset Status",
        headerName: "Status",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "messageAction",
        description: "Send a message to the project contact",
        headerName: "Message",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => {
                openMessageDialog(true);
                setSelectedData(params.row);
              }}
              aria-label="send message"
              size="small"
              sx={{ ml: 1 }}
            >
              <EmailIcon />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    "Project Identifier": false,
    "Quantification Protocol": !isMobile,
    Vintage: !isMobile,
  });
  useEffect(() => {
    setColumnVisibilityModel({
      "Project Identifier": false,
      "Quantification Protocol": !isMobile,
      Vintage: !isMobile,
      Quantity: !isMobile,
      "Emission Offset Project Developer": !isMobile,
    });
  }, [isMobile]);

  return (
    <Box sx={{ height: "90vh", width: "100%" }}>
      <StyledDataGrid
        rows={data}
        columns={columns}
        loading={data.length === 0}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        initialState={{
          filter: {
            filterModel: {
              items: [{ field: "Emission Offset Status", operator: "equals", value: "Active" }],
            },
          },
        }}
      />
      <MessageDialog
        isOpen={isOpen}
        data={selectedData}
        handleClose={() => openMessageDialog(false)}
      />
    </Box>
  );
};

export default Registry;
