import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import App from "./App";
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

LicenseInfo.setLicenseKey(
  "929e41f9516d4deb009929cc57f9fc84Tz01MDI0MyxFPTE2OTM4NTAzMTkxMTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

ReactDOM.createRoot(document.querySelector("#app")).render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>
);
