import React from "react";

const AlertContext = React.createContext();

export function useAlertContext() {
  return React.useContext(AlertContext);
}

export const alertReducer = (state = [], action) => {
  switch (action.type) {
    case "OPEN":
      return {
        open: true,
        ...action.payload,
      };
    case "CLOSE":
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default AlertContext;
