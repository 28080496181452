import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useAlertContext } from "./AlertContext";

export default function TransitionAlert() {
  const { dispatch, state } = useAlertContext();

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={state.open}>
        <Alert
          severity={state.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                dispatch({ type: "CLOSE" });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {state.message}
        </Alert>
      </Collapse>
    </Box>
  );
}
