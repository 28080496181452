import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isNil, pick } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlertContext } from "./AlertContext";
import useLocalStorage from "./useLocalStorage";

// use the dev (unrestricted) key for dev, otherwise the public (but restricted) api key
const API_KEY =
  process.env.REACT_APP_CHAMENITE_API_KEY ||
  "AIzaSyBAUfbN1-hBYwkcHaqbG5mq7wvwYQ0Onoc";

// see chamenite-server, and api gateways on spring-melody GCP
const GATEWAY_URL = "https://registries-api-gateway-ciwzx1hg.wl.gateway.dev";

const schema = yup.object().shape({
  fullName: yup.string().max(100, "Too long.").required("Name is required."),
  email: yup.string().email().required("Email is required."),
  subject: yup.string().max(100, "Too long.").required("Subject is required."),
  body: yup.string().max(4000, "Too long.").required("Body is required."),
});

const MessageDialog = ({ isOpen, handleClose, data }) => {
  const [formDefaults, setFormDefaults] = useLocalStorage("form-defaults", {});

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: formDefaults.fullName || "",
      email: formDefaults.email || "",
      subject: "",
      body: "",
    },
  });

  const { dispatch } = useAlertContext();

  const onClose = () => {
    setIsSubmitting(false);
    handleClose();
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const onSubmit = async (message) => {
    // post this data to our microservice
    const body = {
      ...message,
      externalProjectId: data["Project Identifier"],
    };

    // save personal data for later use
    setFormDefaults(pick(body, ["fullName", "email"]));

    try {
      setIsSubmitting(true);
      const response = await fetch(
        `${GATEWAY_URL}/sendMessage?key=${API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const json = await response.json();
      console.info(json);

      if (response.status === 200) {
        dispatch({
          type: "OPEN",
          payload: { message: "Success! Sent message.", severity: "success" },
        });
        reset(pick(body, ["fullName", "email"]));
      } else {
        dispatch({
          type: "OPEN",
          payload: {
            message: `Error. Sorry, we were unable to send your message. ${json?.message}`,
            severity: "error",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "OPEN",
        payload: {
          message: `Error. Sorry, we were unable to send your message. ${err}`,
          severity: "error",
        },
      });
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form noValidate autoComplete="off">
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent sx={{ minWidth: 600 }}>
          <DialogContentText gutterBottom>
            Send a message to the project contact, expressing your interest.
          </DialogContentText>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="My Full Name"
                variant="standard"
                fullWidth
                autoFocus
                error={!isNil(errors.fullName?.message)}
                helperText={errors.fullName?.message || " "}
                placeholder="John Doe"
                required
              />
            )}
            name="fullName"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="My Email"
                variant="standard"
                fullWidth
                error={!isNil(errors.email?.message)}
                helperText={errors.email?.message || " "}
                placeholder="john@company.org"
                required
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Subject"
                variant="standard"
                fullWidth
                error={!isNil(errors.subject?.message)}
                helperText={errors.subject?.message || " "}
                placeholder="I'm interested in your project"
                required
              />
            )}
            name="subject"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Message to Project Contact"
                fullWidth
                multiline
                rows={6}
                variant="filled"
                required
                error={!isNil(errors.body?.message)}
                helperText={errors.body?.message || " "}
              />
            )}
            name="body"
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            onClick={() => handleSubmit(onSubmit)()}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MessageDialog;
